<template>
  <div v-loading="loading" class="brand-container">
    <el-form ref="form" class="upload-form" :model="form">
      <div class="title">
        <h2>My Branding</h2>
      </div>
      <div class="upload-file">
        <div class="corporate-logo-upload">
          <p class="corporate-txt">Corporate Logo:</p>
          <el-form-item>
            <el-upload
              ref="upload"
              class="upload-files"
              drag
              :action="bucketUrl"
              :on-change="handleUploadChangeLogo"
              :headers="bucketHeaders"
              name="image"
              :file-list="practiceLogo"
              :on-success="setLogoResponseUrl"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                <p class="drag-txt">Drag logo here or</p>
                <p class="click-upload">click to upload</p>
              </div>
              <p class="upload-hint">File must be 370x200</p>
            </el-upload>
          </el-form-item>
        </div>
        <div class="favicon-upload">
          <p class="favicon-txt">Favicon:</p>
          <el-form-item>
            <el-upload
              ref="upload"
              class="upload-files"
              drag
              :action="bucketUrl"
              :headers="bucketHeaders"
              name="image"
              :on-change="handleUploadChangeFavicon"
              :file-list="practiceFavicon"
              :on-success="setFaviconResponseUrl"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                <p class="drag-txt">Drag favicon here or</p>
                <p class="click-upload">click to upload</p>
              </div>
              <p class="upload-hint">File must be 50x50</p>
            </el-upload>
          </el-form-item>
        </div>
      </div>
      <BaseButton
        type="cta"
        class="save-btn"
        :disabled="validateLogo !== '' || validateFavicon !== '' || !uploadBtn"
        @click="handleFormSubmit('form')"
      >
        Upload
      </BaseButton>
      <div class="message">
        <el-tag v-if="upload === 'fail'" class="tag" type="danger"> Error occurred while uploading files! </el-tag>
        <el-tag v-if="upload === 'success'" class="tag" type="success"> Files Uploaded Successfully! </el-tag>
        <el-tag v-if="validateLogo !== ''" class="tag" type="danger">
          {{ validateLogo }}
        </el-tag>
        <el-tag v-if="validateFavicon !== ''" class="tag" type="danger">
          {{ validateFavicon }}
        </el-tag>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import { Message } from 'element-ui';
import BaseButton from "@/components/common/BaseButton.vue";

export default {
  name: "LocationBrand",
  components: {
    BaseButton,
  },
  data() {
    return {
      loading: false,
      practiceLogo: [],
      practiceFavicon: [],
      form: {
        uploadedLogoUrl: "",
        uploadedFaviconUrl: "",
      },
      upload: "",
      validateLogo: "",
      validateFavicon: "",
      uploadBtn: false,
    };
  },
  computed: {
    ...mapGetters("accountsModule", ["getOrgDetails"]),
    bucketUrl() {
      return `${process.env.VUE_APP_API_ROOT}/bucket/v1/image`;
    },
    bucketHeaders() {
      const config = {
        Authorization: `Bearer ${localStorage.access_token}`,
      };
      return config;
    },
    notValidated() {
      return this.form.name === "" || this.form.uploadedFileUrl === "";
    },
  },
  created() {
    if (this.getOrgDetails.logoimgUrl !== "") {
      const logoList = this.getOrgDetails.logoimgUrl.split("/");
      const practiceLogoName = logoList[logoList.length - 1];
      this.practiceLogo = [{ name: practiceLogoName, url: this.getOrgDetails.logoimgUrl }];
    }
    if (this.getOrgDetails.favimgUrl !== "") {
      const faviconList = this.getOrgDetails.favimgUrl.split("/");
      const practiceFaviconName = faviconList[faviconList.length - 1];
      this.practiceFavicon = [{ name: practiceFaviconName, url: this.getOrgDetails.favimgUrl }];
    }
  },
  methods: {
    ...mapActions(["updateBrandLogoAndFavicon"]),
    handleUploadChangeFavicon(file) {
      this.practiceFavicon = [file];
    },
    handleUploadChangeLogo(file) {
      this.practiceLogo = [file];
    },
    setFaviconResponseUrl(response) {
      this.form.uploadedFaviconUrl = response;
      this.validateFile(response, "favicon");
    },
    setLogoResponseUrl(response) {
      this.form.uploadedLogoUrl = response;
      this.validateFile(response, "logo");
    },
    validateFile(url, fileType) {
      const img = new Image();
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      // eslint-disable-next-line func-names
      img.onload = function () {
        if (fileType === "logo") {
          _this.validateLogo =
            this.width === 370 && this.height === 200
              ? ""
              : `Selected logo is ${this.width}x${this.height}, the logo must be a fixed size of 370x200!`;
        } else {
          _this.validateFavicon =
            this.width === 50 && this.height === 50
              ? ""
              : `Selected favicon is ${this.width}x${this.height}, thel favicon must be a fixed size of 50x50!`;
        }
        _this.uploadBtn = _this.validateLogo === "" || _this.validateFavicon === "";
      };
      img.src = url;
    },
    async handleFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) {
          return false;
        }
        if (this.getOrgDetails.logoimgUrl !== "" && this.form.uploadedLogoUrl === "") {
          this.form.uploadedLogoUrl = this.getOrgDetails.logoimgUrl;
        }
        if (this.getOrgDetails.logoimgUrl !== "" && this.form.uploadedLogoUrl === "") {
          this.form.uploadedFaviconUrl = this.getOrgDetails.favimgUrl;
        }
        this.loading = true;
        const payload = {
          logoimgUrl: this.form.uploadedLogoUrl,
          favimgUrl: this.form.uploadedFaviconUrl,
          id: this.getOrgDetails.id,
          name: this.getOrgDetails.name,
          phone: this.getOrgDetails.phone,
          email: this.getOrgDetails.email,
          address1: this.getOrgDetails.address1,
          address2: this.getOrgDetails.address2,
          city: this.getOrgDetails.city,
          region: this.getOrgDetails.region,
          postalCode: this.getOrgDetails.postalCode,
          country: "United States",
        };
        await this.updateBrandLogoAndFavicon(payload);
        this.loading = false;
        this.upload = "success";
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
  width: 180px;
}
.tag {
  width: 800px;
  text-align: center;
  margin-bottom: 50px;
  font-size: 14px;
}
.corporate-logo-upload,
.favicon-upload {
  width: 300px;
}
.drag-txt {
  margin-top: -20px;
}
.click-upload {
  margin-top: -20px;
  color: #f05a28;
}
.save-btn {
  margin-bottom: 25px;
  width: 100px;
}
.favicon-txt,
.corporate-txt {
  font-weight: 600;
}
.upload-hint {
  margin-top: -15px;
}
.title {
  padding: 25px 0px;
}
.upload-file {
  display: flex;
}
.favicon-upload {
  margin-left: 50px;
}
</style>
