<template>
  <div v-loading="loading">
    <BaseComponent :is-loading="!pageHasLoaded" class="licenses-add-medical">
      <template slot="content">
        <div v-for="fulfillment in orderData.fulfillments" :key="fulfillment.id">
          <BaseCard class="fulfillment-wrapper">
            <div class="fulfillment">
              <div class="fulfillment-header">
                <div class="fulfillment-details">
                  <span class="info fulfillment-vendor">
                    <p class="info-label">Vendor:</p>
                    <p class="value">{{ fulfillment.vendorName }}</p>
                  </span>
                  <!-- <span class="info patient-details">
                <p class="info-label">Patient:</p>
                <p class="value">Kapogi ku naman ser</p>
              </span> -->
                  <span class="info fulfillment-id">
                    <p class="info-label">Fulfillment No.:</p>
                    <p class="value">{{ fulfillment.fulfillmentId }}</p>
                  </span>
                  <span class="info">
                    <p class="info-label">Fulfillment Status:</p>
                    <DynamicStatus :status="fulfillment.status" status-type="fulfillment-order-history" />
                  </span>
                </div>
                <div class="header-actions">
                  <BaseButton
                    v-if="fulfillment.parcels.length > 0"
                    type="secondary"
                    class="track-package-btn"
                    @click="handleShowParcel(fulfillment)"
                  >
                    Track Package
                  </BaseButton>
                  <BaseButton type="secondary" class="product-support-btn" @click="support()">
                    Product Support
                  </BaseButton>
                  <!-- <BaseButton type="secondary" class="replace-order-btn" @click="replaceOrder()">
                    Return or Replace
                  </BaseButton> -->
                </div>
              </div>
              <div class="bottom">
                <div class="column">
                  <div class="shipping-summary">
                    <p v-if="fulfillment.shippingApplied && fulfillment.carrierService">
                      Paid ${{ fulfillment.shippingApplied.toFixed(2) }} for
                      {{ formattedService(fulfillment.carrierService) }}
                      shipping in checkout
                    </p>
                  </div>
                  <div v-if="fulfillment.refundList.length > 0">
                    <p v-for="refundObj in fulfillment.refundList" :key="refundObj.id" class="refund-summary">
                      The amount ${{ refundObj.amount.toFixed(2) }} refunded on {{ formatDate(refundObj.createdAt) }}
                      <span v-if="refundObj.refundedBy !== ''" class="refunded-name">
                        by {{ refundObj.refundedBy }}
                      </span>
                    </p>
                  </div>
                  <!-- <BaseButton
                    v-for="(parcel, index) in fulfillment.parcels"
                    :key="parcel.id"
                    target="_blank"
                    class="tracking-link"
                    @click="trackPackage(parcel.trackingUrl)"
                  >
                    {{ trackingLinkMsg(fulfillment.parcels, index) }}
                  </BaseButton> -->
                  <!-- <BaseButton
                    v-if="fulfillment.parcels.length > 0"
                    class="tracking-link"
                    @click="handleShowParcel(fulfillment)"
                  >
                    {{ trackingLinkMsg(fulfillment.parcels, index) }}
                  </BaseButton> -->
                </div>
                <!-- <div class="column btn-track">
              <BaseButton type="secondary" class="track-package-btn" @click="trackPackageTest()">
                Track Package
              </BaseButton>
            </div> -->
                <div class="row">
                  <div class="product-details-col">
                    <div class="fulfillment-product-table">
                      <div class="fulfillment-list-header col-wrapper">
                        <div class="fulfillment-col-1">PRODUCT</div>
                        <div class="fulfillment-col-2">
                          <div>PRACTICE PRICE</div>
                          <div class="quantity">QTY</div>
                          <div>SUBTOTAL</div>
                          <div></div>
                        </div>
                      </div>
                      <div class="fulfillment-product-table--body-wrapper">
                        <div
                          v-for="product in fulfillment.items"
                          :key="product.productId"
                          class="fulfillment-list-item col-wrapper"
                        >
                          <div class="fulfillment-col-1">{{ product.productName }}</div>
                          <div class="fulfillment-col-2">
                            <div>${{ product.price.toFixed(2) }}</div>
                            <div class="quantity">{{ product.qty }}</div>
                            <div>${{ product.subtotal.toFixed(2) }}</div>
                            <div>
                              <BaseButton class="column reorder" @click="reorderProduct(product)">
                                Reorder Item
                              </BaseButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <BaseButton type="cta" class="duplicate-btn" @click="duplicateOrder(fulfillment)">
                      Duplicate Order
                    </BaseButton>
                    <BaseButton
                      v-if="isSimpatraAdmin && fulfillment.fulfillmentId !== refund.fulfillmentId"
                      type="secondary"
                      class="refund-btn"
                      @click="startRefund(fulfillment)"
                    >
                      Refund this fulfillment
                    </BaseButton>
                    <div v-if="fulfillment.fulfillmentId === refund.fulfillmentId">
                      <el-input
                        v-model="refund.refundAmount"
                        :placeholder="`Enter the refund amount here, up to $${calculateRefundAmount(
                          fulfillment
                        ).toFixed(2)}`"
                        class="refund-amount"
                      />
                      <BaseButton type="secondary" @click="refund.fulfillmentId = ''"> Cancel </BaseButton>
                      <BaseButton
                        type="cta"
                        class="refund-submit"
                        :disabled="invalidRefund(fulfillment)"
                        @click="processRefund"
                      >
                        Process Refund
                      </BaseButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BaseCard>
        </div>
        <div v-if="order.discounts.length > 0">
          <div v-for="discount in order.discounts" :key="discount.id">
            <div class="discounts">
              <div class="discount">
                <span class="discount-label">
                  {{ discount.promoBanner }}
                </span>
                <span class="discount-amount"> - ${{ discount.discountAmount.toFixed(2) }} </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </BaseComponent>
    <BaseModal
      v-if="showModal"
      class="tracking-modal"
      :is-visible="showModal"
      :is-loading="loadingModal"
      :handle-close="() => handleCloseModal()"
      title="Track This Shipment"
      custom-width="25%"
    >
      <div v-for="(parcel, index) in selectedParcels" :key="parcel.id" class="parcel">
        <span>Package {{ index }}: </span>
        <a target="_blank" @click="trackPackage(parcel.trackingUrl)">{{ parcel.trackingCode }}</a>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { get } from "lodash";
import moment from "moment";
import { Message, MessageBox } from "element-ui";
import { monthDayYearTime, timeSince } from "@/plugins/moment";
import BaseCard from "@/components/common/BaseCard.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import DynamicStatus from "@/components/common/AntDesign/DynamicStatus.vue";
import notification from "@/utils/notifications";
import BaseComponent from "@/components/shared-components/BaseComponent.vue";
import BaseModal from "@/components/common/AntDesign/BaseModal.vue";
import userMe from "@/services/user-me";

export default {
  name: "LocationOrderFulfillments",
  components: {
    BaseCard,
    BaseButton,
    DynamicStatus,
    BaseComponent,
    BaseModal,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pageHasLoaded: false,
      loading: false,
      amount: 0,
      refund: {
        vendorId: "",
        fulfillmentId: "",
        refundAmount: null,
      },
      orderReceipt: [],
      showModal: false,
      loadingModal: false,
      selectedParcels: [],
    };
  },
  computed: {
    ...mapGetters(["getCurrentProduct", "getCurrentUser"]),
    orderData() {
      this.order.fulfillments.forEach((fulfillment) => {
        fulfillment.refundAmount = 0;
        const receiptData = get(this, "orderReceipt", []);
        const refundInfo = receiptData.filter(
          (orderFulfillment) =>
            fulfillment.fulfillmentId === orderFulfillment.fulfillmentId && orderFulfillment.amount < 0
        );
        fulfillment.refundList = refundInfo;
      });
      return this.order;
    },
    getMyRoles() {
      return userMe.getMyRoles();
    },
    isSimpatraAdmin() {
      return this.getMyRoles.isSimpatraAdmin;
    },
  },
  created() {
    // const promises = this.order.fulfillments.map((fulfillment) => {
    //   const orderReceiptFulfillment = this.orderReceipt.find(
    //     (orderFulfillment) => orderFulfillment.fulfillmentId === fulfillment.fulfillmentId
    //   );
    //   // fulfillment.items.map((fulfillmentItem) => {
    //   //   const productInLineItem = orderReceiptFulfillment.receiptItems.find((lineItem) => fulfillmentItem)
    //   // })
    //   const isFulfillmentRefunded = orderReceiptFulfillment.receiptItems.some(
    //     (lineItem) => lineItem.productName === "Refund"
    //   );
    //   fulfillment.isRefunded = isFulfillmentRefunded;
    //   // console.log(isFulfillmentRefunded, "isFulfillmentRefunded===")
    //   // console.log(orderReceiptFulfillment.fulfillmentId, "orderFulfillment")
    //   // console.log(fulfillment.fulfillmentId, "fulfillment")
    //   console.log(fulfillment, "fulfillments");
    //   return; // this.fetchDescription(item);
    // });
    // // wait for all the API requests to complete
    // Promise.all(promises)
    //   .then(() => {
    //     // execute another function once the loop is done
    //     console.log(this.order, "qweqwe");
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
    // this.order.fulfillments.map((fulfillment) => {
    //   fulfillment.refundAmount = 0;
    //   fulfillment.refundList = [];
    //   const fulfillmentData = this.orderReceipt.find(
    //     (orderFulfillment) => fulfillment.fulfillmentId === orderFulfillment.fulfillmentId
    //   );
    //   console.log(fulfillmentData, "orderReceipt");
    //   console.log(fulfillment, "fulfillment");
    //   fulfillment.items = fulfillmentData.receiptItems;
    //   if (this.orderReceipt && isArray(this.orderReceipt)) {
    //     const refundProductIndex = this.orderReceipt.findIndex(
    //       (receipt) =>
    //         fulfillment.fulfillmentId === receipt.fulfillmentId && receipt.receiptItems[0].productName === "Refund"
    //     );
    //     if (refundProductIndex >= 0) {
    //       fulfillment.refundAmount = Math.abs(this.orderReceipt[refundProductIndex].amount) + fulfillment.refundAmount;
    //       fulfillment.refundAt = moment(String(this.orderReceipt[refundProductIndex].createdAt)).format("MM/DD/YYYY");
    //       fulfillment.refundData = {
    //         amount: Math.abs(this.orderReceipt[refundProductIndex].amount),
    //         refundAt: fulfillment.refundAt,
    //         refundedBy: this.orderReceipt[refundProductIndex].refundedBy,
    //       };
    //       fulfillment.refundList.push(fulfillment.refundData);
    //     }
    //   }
    // });
    // this.orderData = this.order;
  },
  async mounted() {
    this.orderReceipt = await this.fetchOrderReceipt({
      locationId: this.order.buyerId,
      purchaseId: this.order.purchaseId,
    });
    this.pageHasLoaded = true;
  },
  methods: {
    ...mapActions({
      addItemToCart: "addItemToCart",
      refundFulfillment: "admin/refundFulfillment",
      setEmptyCart: "setEmptyCart",
      fetchBuyerReceipt: "fetchBuyerReceipt",
    }),
    ...mapActions("ordersModule", ["fetchOrderReceipt"]),
    ...mapActions("productsModule", ["fetchProductById"]),
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    calculateRefundAmount(fulfillment) {
      if (fulfillment.refundAmount !== undefined) {
        return fulfillment.total - fulfillment.refundAmount;
      }
      return fulfillment.total;
    },
    replaceOrder() {
      MessageBox("The feature will be coming soon!")
        .then(() => {})
        .catch(() => {});
    },
    trackPackageTest() {
      MessageBox("Package Tracking can currently be found below the Seller Information!")
        .then(() => {})
        .catch(() => {});
    },
    trackPackage(link) {
      window.open(link);
    },
    support() {
      this.$router.push({ name: "Support" }).catch(() => {});
    },
    statusType(status) {
      if (status === "Processing") {
        return "warning";
      }
      if (status === "Shipped") {
        return "success";
      }
      return "danger";
    },
    invalidRefund(fulfillment) {
      const value = parseFloat(this.refund.refundAmount);
      if (fulfillment.refundAmount !== undefined) {
        if (value > 0 && value <= fulfillment.total.toFixed(2) - fulfillment.refundAmount.toFixed(2)) {
          return false;
        }
        return true;
      }
      if (value > 0 && value <= fulfillment.total.toFixed(2)) {
        return false;
      }
      return true;
    },
    startRefund(fulfillment) {
      this.refund.vendorId = fulfillment.vendorId;
      this.refund.fulfillmentId = fulfillment.fulfillmentId;
      this.dialogVisible = true;
    },
    async processRefund() {
      this.loading = true;
      try {
        await this.refundFulfillment(this.refund)
          .then(async () => {
            Message({
              type: "success",
              showClose: true,
              message: "The amount refunded successfully.",
            });
            const data = {
              buyerId: this.order.buyerId,
              purchaseId: this.order.purchaseId,
            };
            await this.fetchBuyerReceipt(data);
          })
          .catch(() => {
            this.refund = {
              vendorId: "",
              fulfillmentId: "",
              refundAmount: null,
            };
          });
      } finally {
        this.refund = {
          vendorId: "",
          fulfillmentId: "",
          refundAmount: null,
        };
      }
      this.loading = false;
    },
    formattedDateTime(date) {
      return monthDayYearTime(date);
    },
    formattedTimeSince(date) {
      return timeSince(date);
    },
    formattedService(s) {
      return [...s]
        .map((char, i) => {
          if (char === "_") {
            return " ";
          }
          if (char === char.toUpperCase() && char[i + 1] > char) {
            return ` ${char.toLowerCase()}`;
          }
          return char;
        })
        .join("")
        .replace("FEDEX ", "");
    },
    trackingLinkMsg(parcels, index) {
      if (parcels.length === 1) {
        return "Track this shipment";
      }
      return `Track package ${index + 1}`;
    },
    duplicateOrder(fulfillment) {
      fulfillment.items.forEach((product) => {
        this.reorderProduct(product);
      });
    },
    async reorderProduct(product) {
      const productDetails = await this.fetchProductById(product.productId);
      const hasNewPricing = productDetails.productPricingId !== product.productPricingId;
      if (hasNewPricing) {
        notification("warning", "Pricing has changed on this product since your last order, please review in cart!");
        await this.addItemToCart({
          buyerId: this.order.buyerId,
          productId: product.productId,
          productPricingId: productDetails.productPricingId,
          qty: product.qty,
        }).then(() => {
          this.setEmptyCart(false);
          notification("success", `The product ${product.productName} successfully added into the cart.`);
        });
      } else {
        await this.addItemToCart({
          buyerId: this.order.buyerId,
          productId: product.productId,
          productPricingId: product.productPricingId,
          qty: product.qty,
        }).then(() => {
          this.setEmptyCart(false);
          notification("success", `The product ${product.productName} successfully added into the cart.`);
        });
      }
    },
    handleShowParcel(fulfillment) {
      this.selectedParcels = fulfillment.parcels;
      this.showModal = true;
    },
    handleCloseModal() {
      this.showModal = false;
      this.selectedParcels = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../marketplace/my-stockroom/order-history/styles/LocationOrderFulfillments.scss";
.tracking-modal .parcel {
  font-size: 16px;
}
.tracking-modal .parcel a {
  font-weight: 600;
}
.refunded-name {
  color: #67c23a;
}
.refund-summary {
  font-size: 14px;
  color: #374458;
  margin-top: -5px;
}
.product-support-btn,
.replace-order-btn,
.track-package-btn {
  width: 180px;
}
.row {
  display: flex;
}
.shipped {
  margin: 0px 20px;
}
.processing {
  margin: 0px 5px;
}
.header {
  margin-right: 110px;
}
.btn-track {
  width: 280px;
  text-align: right;
}
.product-details-col {
  width: 100%;
}
.btn {
  padding: 5px 0px;
}
.btn-col {
  width: 290px;
  text-align: right;
  margin-top: 25px;
}
.duplicate-btn {
  margin-right: 20px;
  margin-top: 10px;
}
.fulfillment-wrapper {
  margin-top: 15px;
}
.order-summary {
  display: flex;
}
.order-number {
  display: inline-block;
  width: 150px;
  color: $orange;
}
.order-date {
  font-size: 14px;
  color: #989898;
  margin-right: 10px;
}
.tooltip {
  &:hover {
    cursor: default;
  }
}
.summary-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.order-total-label {
  font-size: 14px;
  color: #989898;
  margin-right: 10px;
}
.order-total {
  font-weight: 600;
  color: $dark-blue;
}

.shipping-summary {
  font-size: 14px;
  color: $dark-blue;
  margin-right: 10px;
  width: 800px;
  padding-top: 5px;
}
.tracking {
  margin-bottom: 15px;
}
.tracking-link {
  color: #67c23a;
  margin-right: 15px;
}
.right {
  margin-left: auto;
  span {
    text-align: right;
  }
}
.column {
  display: inline-block;
}
.name {
  width: 200px;
}
.price {
  width: 80px;
}
.qty {
  width: 80px;
}
.sub {
  width: 100px;
}
.reorder {
  width: 125px;
  text-align: right;
}
.discounts {
  color: #67c23a;
  text-align: right;
  font-size: 14px;
  padding: 20px 30px 0px 0px;
}
.discount {
  margin-top: 5px;
}
.discount-label {
  font-size: 12px;
}
.discount-amount {
  display: inline-block;
  width: 100px;
}
.refund-btn {
  margin-top: 10px;
}
.refund-amount {
  margin: 10px 0px;
}
.refund-submit {
  margin-left: 10px;
}
@media screen and (max-width: 800px) {
  .pkg,
  .price,
  .qty {
    display: none;
  }
}
</style>
